.resource-access-1st-react-select-container {

    width: 90%;
}
.resource-access-2nd-react-select-container {

    width: 82%;
}

.react-bootstrap-daterangepicker-container {
    width: 100%;
}