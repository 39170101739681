// .react-datepicker {
//     width: 140%;
// }

.rafrom{
    padding-left: 6%;
    border: 0;
    height: 35px;
}
.rato{
    padding-left: 6%;
    border: 0;
    height: 35px;
}
.react-datepicker-wrapper{
    width: 93%;
}

.ct-x-axis-title{
    fill: white;
    font-family: none;
}
.ct-y-axis-title{
    fill: white;
    font-family: none;
    margin-left: 2px;
}