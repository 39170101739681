.col-1-5{
    -ms-flex: 0 0 19%;
     flex: 0 0 19%;
     max-width: 19%;
     width: 100%;
     min-height: 1px;
     padding-right: 10px;
     padding-left: 10px;
   }
 .col-1-5-box {
    border:1px solid #ccc;
    margin: 0px 0px;
 }
 .col-fix-50{
    width: 50%;
    float: left;
    margin: 12px 0px;
    background-color: #fff;
 }
 .col-fix-50 p{
    font-size: 12px;
    text-align: center;
    margin: 0px;
 }
 .col-1-5 h6{
    border-top:1px solid #ccc;
    padding: 15px 5px;
 }
 .col-fix-50+.col-fix-50{
    border-left: 1px solid #ccc;;
 }
 @media only screen and (min-width: 320px) and (max-width: 767px){
   .col-1-5{
    -ms-flex: 0 0 99%;
     flex: 0 0 99%;
     max-width: 99%;
     width: 100%;
     min-height: 1px;
     padding-right: 10px;
     padding-left: 10px;
   }
 }

.max-lines {
   display: block;
   text-overflow: ellipsis;
   word-wrap: break-word;
   overflow: hidden;
   max-height: 5.6em;
   min-height: 5.6em;
   line-height: 1.8em;
 }